<template>
  <div>
    <v-form ref="form">
      <v-text-field
        v-model="umUser.name"
        label="Name"
        required
        :rules="[v => !!v || 'Name is required']"
      ></v-text-field>

      <v-autocomplete
        :items="countries"
        item-text="title"
        item-value="code"
        v-model="umUser.countryCode"
        label="Country"
        required
        :rules="[v => !!v || 'Country is required']"
      ></v-autocomplete>

      <v-text-field
        :prefix="umUser.countryCode"
        v-model="umUser.mobileNumber"
        label="Contact"
        required
        :rules="[v => !!v || 'Contact Number is required']"
      ></v-text-field>

      <v-text-field v-model="umUser.email" label="E-mail" required :rules="emailRules"></v-text-field>

      <v-text-field
        v-if="type != 'UPDATE'"
        v-model="umUser.password"
        label="Password"
        clearable
        ref="password"
        :rules="passwordRules"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'visibility' : 'visibility_off'"
        @click:append="showPassword = !showPassword"
      ></v-text-field>

      <v-autocomplete
        :items="organization"
        item-text="name"
        item-value="_id"
        v-model="dbUser.organization_id"
        label="Organization"
        :rules="[v => !!v || 'Organizations is required']"
      ></v-autocomplete>

      <v-select v-model="dbUser.permissions" :items="permissions" multiple label="User Permissions"></v-select>

      <v-select
        v-model="dbUser.roles"
        :items="roles"
        multiple
        label="User Roles"
        required
        :rules="[v => v.length > 0 || 'User Role is required']"
      ></v-select>
    </v-form>

    <v-dialog width="600" v-model="dialog" v-if="type == 'UPDATE'">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Reset Password</v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm8 md8>
              <v-text-field
                v-model="umUser.password"
                label="New Password"
                clearable
                ref="password"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                @click:append="showPassword = !showPassword"
                solo
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updateUser">Reset</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn class="mr-5" color="primary" @click="$router.push({ name: 'BuyerUsers' })">Back</v-btn>
    <v-btn class="mr-5" color="primary" @click="updateUser" v-if="type == 'UPDATE'">Update</v-btn>
    <v-btn class="mr-5" color="primary" @click="createUser" v-if="type == 'CREATE'">Create</v-btn>
    <v-btn color="primary" @click="resetPassword" v-if="type== 'UPDATE'">Reset Password</v-btn>
  </div>
</template>

<script>
import BuyerRestResource from "../../services/dataServiceBuyer.js";
import CommonRestResouce from "@/services/dataServiceAdmin.js";

const commonService = new CommonRestResouce();
const service = new BuyerRestResource();

export default {
  props: {
    umUser: Object,
    dbUser: Object,
    type: String
  },
  data() {
    return {
      dialog: false,
      showPassword: false,
      organization: [],
      countries: [],
      roles: ["USER", "ADMIN", "SUPPLIER", "BUYER"],
      permissions: ["CROSSTAB"],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      mobileNumberRules: [value => !!value || "Mobile number required."],
      passwordRules: [
        value => !!value || "Password required.",
        value => (value || "").length >= 8 || `Min 8 characters`
      ]
    };
  },
  mounted() {
    this.loadOrganization();
    this.fetchCountries();
  },
  methods: {
    fetchCountries() {
      service.getConfig("countries").then(r => {
        this.countries = r.data;
      });
    },
    loadOrganization() {
      service.fetchOrganizations().then(r => {
        this.organization = r.data.organizations;
      })
    },
    updateUser() {
      if (this.$refs.form.validate()) {
        this.updateDBAndUMUser(this.dbUser, this.umUser);
      }
    },
    createUser() {
      if (this.$refs.form.validate()) {
        this.$setLoader();
        service.register({ ...this.umUser, ...this.dbUser }).then(r => {
          if (r != undefined) {
            alert("Successfully created user");
            this.$router.push({ name: "BuyerUsers" });
          }

          this.$disableLoader();
        })
      }
    },
    updateDBAndUMUser(dbUser, umUser) {
      dbUser.updated_at = Date.now();
      dbUser.updated_by = this.$store.state.userProfile.name

      this.$setLoader();
      service.updateUser(dbUser).then(() => {
        umUser.updated_at = Date.now();
        // TODO: Add created_by and updated_by

        commonService.updateUMUser(umUser).then(() => {
          this.$disableLoader();
          alert("Successfully updated user");
          this.dialog = false
        }).catch(() => {
          this.$disableLoader();
          alert("Failed to update user");
          this.dialog = false
        });
      }).catch(() => {
        this.$disableLoader();
        alert("Failed to update user");
        this.dialog = false
      });
    },

    resetPassword() {
      this.dialog = true;
    }
  }
};
</script>